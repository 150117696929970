<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('order.title') }}
          <span class="text-muted pt-2 font-size-sm d-block">{{ $t('order.orders_count', {n: orderTotalCount}) }}</span>
        </h3>
      </div>
    </div>
    <div v-visibility-change="visibilityChange" class="card-body">
      <div class="row ">
          <div class="col-lg-9 col-xl-8 py-3">
            <form>
              <div class="form-row">
              <div class="col">
                  <label>{{ $t('order.location') }}</label>
                  <select class="form-control" v-model="filter.location_id">
                    <option value="">{{$t('common.all')}}</option>
                    <option v-for="location in locationList" :value="location.id">{{ location.name }}</option>
                  </select>
              </div>
              <div class="col">
                  <label>{{ $t('order.filter_status') }}</label>
                  <select class="form-control" v-model="filter.status_id">
                    <option value="">{{$t('common.all')}}</option>
                    <option v-for="status in statuses" :value="status.id">{{ $t(`order.status.${status.name}`) }}</option>
                  </select>
              </div>
              <div class="col">
                  <label>{{ $t('order.started_at') }}</label>
                  <date-range-picker
                      ref="picker"
                      :show-dropdowns="true"
                      :linked-calendars="true"
                      :close-on-esc="true"
                      :ranges="false"
                      v-model="dateRange"
                      @update="updateDataRange"
                      control-container-class=""
                      :locale-data="$t('data-range-piker')"
                  >
                    <div slot="input" slot-scope="data" style="min-width:250px;" class="input-group">
                      <div v-if="data.startDate" type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2">{{ data.startDate | formatDate }} - {{ data.endDate | formatDate }}</div>
                      <div v-else type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2"></div>
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="flaticon-event-calendar-symbol"></i></span>
                      </div>
                    </div>
                    <div slot="footer" slot-scope="data" class="drp-buttons">
                      <span data-v-4f8eb193="" class="drp-selected">{{data.rangeText}}</span>
                      <button @click="clickDataRangeCencel(data)" type="button"
                              class="cancelBtn btn btn-sm btn-secondary">{{data.locale.cancelLabel}} </button>
                      <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                              class="applyBtn btn btn-sm btn-success">{{data.locale.applyLabel}} </button>
                    </div>
                  </date-range-picker>
              </div>
            </div>
            </form>
          </div>
        </div>
      <!--begin: Datatable-->
      <core-datatable :loaded="loaded" v-bind:columns.sync=columns :showFooter="true"
                      :items="orderList" v-bind:filter.sync="filter"
                      :totalCount="orderTotalCount">
        <template #service="{ item }">
          <button class="btn btn-light btn-sm" @click="showInfoModal(item)">
            <font-awesome-icon icon="stream"/>
            {{ $t('order.services') }}
          </button>
        </template>
        <template #created_at="{ item }">
          {{item.created_at | formatDate}}
        </template>
        <template #ts_start="{ item }">
          {{item.ts_start | formatDate}}
        </template>
        <template #ts_end="{ item }">
          {{item.ts_end | formatDate}}
        </template>
        <template #total_cost="{ item }">
          {{item.total_cost | formatPrice}}
        </template>
        <template #payment="{ item }">
          <span v-if="item.payment">{{ $t(`order.pm.${item.payment.name}`) }}</span>
          <span v-else>-</span>
        </template>
        <template #status="{ item, deepFind, getValuesItem, column }">
          <span class="label label-lg label-inline"
                :class="'label-light-' + getValuesItem(item.status_id, column).class">{{ $t(`order.status.${getValuesItem(item.status_id, column).name}`) }}</span>
        </template>
        <template #action="{ item }">
          <a v-if="isCanPaided(item) && item.location.is_online_payment_enabled" class="btn btn-sm btn-icon btn-light-success mr-2"
             target="_blank" :href="item.guid | payUrl">
            <font-awesome-icon class="icon-md" icon="credit-card"></font-awesome-icon>
          </a>
          <a v-if="isCanCanceled(item)" class="btn btn-sm btn-icon btn-light-danger mr-2"
             @click="showDialogCancelModal(item)">
            <font-awesome-icon class="icon-md" icon="ban"></font-awesome-icon>
          </a>
        </template>
      </core-datatable>
      <core-info v-bind:show.sync=showInfo width="500" :title="$t('order.services_in_invoice', {order_id: (selected ? selected.id : '')})">
        <template>
          <core-datatable v-if="selected" :loaded="true" v-bind:columns.sync="serviceInfoColumns" :items="selected.products">
            <!--<template #cost="{ item }">-->
              <!--{{item.cost | formatPrice}}-->
            <!--</template>-->
            <template #total_cost="{ item }">
              {{item.cost | formatPrice}}
            </template>
          </core-datatable>
          <hr>
          <div class="row">
            <div class="col-9">{{ $t('order.total') }}</div>
            <div class="col-3">{{selected.cost | formatPrice}}</div>
          </div>
          <div class="row">
            <div class="col-9">{{ $t('order.discount') }}</div>
            <div class="col-3">{{selected.discount}}</div>
          </div>
          <div class="row">
            <div class="col-9"><b>{{ $t('order.total_with_discount') }}</b></div>
            <div class="col-3"><b>{{ selected.total_cost | formatPrice }}</b></div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <a v-if="isCanPaided(selected)" class="btn btn-sm btn-light-success mr-2"
                 target="_blank" :href="selected.guid | payUrl">
                <font-awesome-icon class="icon-md" icon="credit-card"></font-awesome-icon>
                {{ $t('order.pay') }}
              </a>
              <a v-if="isCanCanceled(selected)" class="btn btn-sm btn-light-danger mr-2"
                 @click="showDialogCancelModal(selected)">
                <font-awesome-icon class="icon-md" icon="ban"></font-awesome-icon>
                {{ $t('order.cancel') }}
              </a>
            </div>
          </div>
        </template>
      </core-info>
      <!--end: Datatable-->
    </div>
    <b-modal v-model="dialogCancelModal" size="md" @ok="goCancel"
             :title="$t('order.cancel_order')" :ok-title="$t('order.yes_cancel')" :cancel-title="$t('order.back')">
      <div class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('order.cancel_your_order') }}</div>
      </div>
    </b-modal>

    <b-modal v-model="dialogPayResultModal" size="md" ok-only @ok="dialogPayResultModal = false"
             :title="$t('pay_result.title_modal')" :ok-title="$t('pay_result.close')">
      <div v-if="$route.query.result == 0" class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="check" class="text-success icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('pay_result.title_ok') }}</div>
        <div>{{ $t('pay_result.body_ok') }}</div>
      </div>
      <div v-else class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('pay_result.title_fail') }}</div>
        <div>{{ $t('pay_result.body_fail') }}</div>
        <div class="m-auto"><a href="#" @click="goPay">{{ $t('pay_result.pay') }}</a></div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import moment from 'moment'
import api from '@/plugins/api'

// https://innologica.github.io/vue2-daterange-picker/#props
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

let cookie = require('vue-cookie');

let bg = require('@/assets/bg/bg-4.jpg')

export default {
  components: { DateRangePicker },
  computed: {
    ...mapGetters({
      orderList: 'orderOrder/list',
      orderPage: 'orderOrder/page',
      orderPerPage: 'orderOrder/perPage',
      orderTotalCount: 'orderOrder/totalCount',
      locationList: 'locationOrder/list',
      loaded: 'orderOrder/loaded'
    })
  },
  data() {
    return {
      paidForm: '',
      dialogPayResultModal: false,
      dialogCancelModal: false,
      dialogPaidModal: false,
      dateRange: {},
      loading: false,
      valid: false,
      error: null,
      selected: {},
      showInfo: false,
      statuses: [
        // { id: 1, name: 'Cart' },
        // { id: 2, name: 'Waiting payment' },
        // { id: 3, name: 'Paid' },
        // { id: 4, name: 'Canceled' },
        // { id: 5, name: 'Credit' },
        // { id: 6, name: 'Partially paid' },
        // { id: 7, name: 'For deposit' },
        { id: 4, name: 'Waiting payment' },
        { id: 5, name: 'Paid' },
        { id: 6, name: 'Canceled' },
        { id: 7, name: 'Credit' },
        { id: 10, name: 'Finished' },
      ],
      columns: [
        { text: 'order.columns.order', value: 'id', key: 'id' },
        { text: 'order.columns.service', slot: 'service', key: 'service', sort: false },
        { text: 'order.columns.location', value: 'location.name', key: 'location_id', sort: false },
        { text: 'order.columns.created_at', slot: 'created_at', key: 'created_at' },
        { text: 'order.columns.user', value: 'user.fio', key: 'user.fio', sort: false },
        { text: 'order.columns.started_at', slot: 'ts_start', key: 'ts_start' },
        { text: 'order.columns.expire_at', slot: 'ts_end', key: 'ts_end' },
        { text: 'order.columns.cost', slot: 'total_cost', key: 'total_cost', sort: false },
        { text: 'order.columns.payment_method', slot: 'payment', key: 'payment', sort: false },
        { text: 'order.columns.status', slot: 'status', key: 'status_id', values: [
            { id: 4, name: 'Waiting payment', class: 'warning' },
            { id: 5, name: 'Paid', class: 'success' },
            { id: 6, name: 'Canceled', class: 'danger' },
            { id: 7, name: 'Credit', class: 'info' },
            { id: 10, name: 'Finished', class: 'success' },
          ]},
        { text: 'order.columns.action', slot: 'action', sort: false },
      ],
      serviceInfoColumns: [
        { text: 'order.serviceInfoColumns.number', value: '#', sort: false},
        { text: 'order.serviceInfoColumns.service', value: 'name', sort: false },
        // { text: 'Цена', slot: 'cost', sort: false },
        { text: 'order.serviceInfoColumns.qty', value: 'quantity', sort: false },
        { text: 'order.serviceInfoColumns.cost', slot: 'total_cost', sort: false },
      ],
      filter: {
        sort: [],
        location_id: '',
        status_id: this.$route.query.status ? this.$route.query.status : '',
        // По умолчанию фильтруем список заказов, в других статусах нет смысла показывать пользователю заказы
        status_ids: '4,5,6,7,10',
        from: '',
        to: '',
        page: 1,
        'per-page': 20,
      }
    }
  },
  mounted() {
    if (this.$route.query.result >= 0) {
      this.dialogPayResultModal = true
    }
    this.$store.dispatch('locationOrder/GetData')
    this.fetch()
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
  methods: {
    goPay() {
      document.location.href = '/checkout/' + this.$route.query.orderId
    },
    clickDataRangeCencel(data) {
      this.dateRange = {}
      data.rangeText = ''
      this.filter.from  = ''
      this.filter.to  = ''
      data.clickCancel()
    },
    updateDataRange(params) {
      this.filter.from  = moment.unix(params.startDate).format('X') / 1000
      this.filter.to  = moment.unix(params.endDate).format('X') / 1000
    },
    fetch() {
      for (let i in this.columns) {
        switch(this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-'+this.columns[i].key
            break;
        }
      }
      this.$store.dispatch('orderOrder/GetData', this.filter)
    },
    visibilityChange(evt, hidden){
      if (!hidden) {
        this.fetch();
      }
    },
    calcTotalCostForSelectedService() {
      let total = 0
      for (let i in this.selected.products) {
        total = total + (this.selected.products[i].cost * this.selected.products[i].quantity)
      }
      return total
    },
    showInfoModal(selected) {
      this.selected = selected
      console.log('selected', selected)
      this.showInfo = !this.showInfo
    },
    showDialogCancelModal(selected) {
      this.selected = selected
      this.dialogCancelModal = true
    },
    goCancel() {
      let self = this;

      let refund = this.selected.payment.key === 'coins';
      api.delete('v2/orders/' + this.selected.id +(refund ? '?refund=1' : ''), ).then(responce => {
        self.$store.dispatch('orderOrder/GetData', self.filter)
        this.dialogCancelModal = false
      })
    },
    isCanCanceled(item) {
      if(item.status_id === 4) {
        return item.ts_end > moment().format('X') //если заказ завершён, то его уже нельзя отменить
      }
      else if(item.status_id === 5 && item.can_canceled_until > moment().format('X') && item.ts_start > (new Date).getTime() / 1000) {
        return true;
      }
      return false;
    },
    isCanPaided(item) {
      if ((item.status_id === 4 && item.should_paid_until > moment().format('X') ) || item.status_id === 7) {
        return true;
      }
      return false;
    },
  }
}
</script>

<style>

</style>
